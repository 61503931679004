import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/adus-increase-property-value-san-diego");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How ADUs Can Increase Your Property’s Value in San Diego.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Accessory dwelling units (ADUs) are becoming increasingly popular as California
                homeowners look for creative ways to enhance their property value or attract buyers.
                No matter your real estate goals, understanding the impact of ADU housing in San
                Diego can help you reach them.
              </CommonParagraph>
              <Heading type="h2">What Are ADUs?</Heading>
              <CommonParagraph>
                Accessory dwelling units, often referred to as granny flats or in-law suites, are
                secondary housing units built on the property of the primary residence. They can be
                either:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Attached to the home</li>
                <li>A standalone structure</li>
                <li>Located within the home itself, such as a converted garage</li>
              </ul>
              <CommonParagraph>
                Designed to be fully contained living units, ADUs feature kitchens and bathrooms.
              </CommonParagraph>
              <Heading type="h2">The Market Demand for ADUs</Heading>
              <CommonParagraph>
                ADU San Diego builders have been busy! Limited housing options and increasing demand
                mean ADUs help fill a critical gap. Many homeowners are choosing to have ADUs built
                on their property to not only create more room for their family but to open up
                rental opportunities and increase value, should{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home/"
                  text="the time ever come to sell"
                />
                .
              </CommonParagraph>
              <CommonParagraph>
                Many buyers see the value of a property with an existing ADU or one that has the
                space and potential for an accessory dwelling unit to be constructed.
              </CommonParagraph>
              <Heading type="h2">The Benefits of Adding an ADU to Your Property</Heading>
              <Heading type="h3">Rental Income Potential</Heading>
              <CommonParagraph>
                It’s easy to see why ADU construction in San Diego is up, considering the{" "}
                <BlogLink
                  url="https://selbysellssd.com/blog/investing-in-rental-properties-in-san-diego"
                  text="rental
                income potential"
                />{" "}
                they provide. Homeowners can rent out their units for quick stays or even long-term
                tenants. This added, steady source of additional income can help offset upcoming
                renovation expenses, mortgage payments, or simply provide more financial security.
              </CommonParagraph>
              <Heading type="h3">Increased Square Footage</Heading>
              <CommonParagraph>
                An ADU can directly contribute to your home’s overall market value. How? By
                expanding the livable space on your property. Buyers are often willing to pay a
                premium for houses with more square footage, especially when said space includes a
                fully functional, private living area.
              </CommonParagraph>
              <Heading type="h3">More Housing Options</Heading>
              <CommonParagraph>
                With more and more people moving into the area, San Diego has a housing shortage on
                its hands. Accessory dwelling units are seen as part of the solution. Whether used
                for out-of-town guests, renters, or multigenerational living, ADUs can provide
                affordable housing options.
              </CommonParagraph>
              <Heading type="h3">Making Use of Initiatives</Heading>
              <CommonParagraph>
                San Diego has made it easier than ever for homeowners to add an ADU on their
                property. To encourage construction, there are now:
              </CommonParagraph>
              <ul className="text-d-5lg text-[#666666] font-Quicksand font-[500] sm:text-d-4lg 2xs_extar:text-d-3lg 2xs_extar:mb-[15px] xs:text-d-2lg list-disc list-inside mb-[30px] flex flex-col gap-y-[10px]">
                <li>Streamlined permit processes</li>
                <li>Reduced fees</li>
                <li>Special initiatives</li>
              </ul>
              <CommonParagraph>
                All of which help make an ADU both achievable and worthwhile.
              </CommonParagraph>
              <Heading type="h3">Attract Buyers</Heading>
              <CommonParagraph>
                Accessory dwelling units are functional and versatile, offering another way to make
                your home not only stand out in the market but command top-dollar. If you’re
                thinking about selling your home, team up with our{" "}
                <BlogLink
                  url="https://selbysellssd.com/real-estate-marketing-services"
                  text="Compass Concierge"
                />{" "}
                professionals who can prepare your property in a way that showcases an existing ADU
                or the potential for the buyer to add one. Such possibilities can help maximize your
                home’s appeal to potential buyers.
              </CommonParagraph>
              <Heading type="h2">Explore the Power of the ADU</Heading>
              <CommonParagraph>
                ADUs are far more than a trendy addition; they’re a practical solution to some of
                San Diego’s most pressing housing challenges. Plus, they have the added benefit of
                increasing your property value! If you’re thinking about selling or buying a home
                with an ADU, knowing the value of such an addition can help you make informed
                decisions.
              </CommonParagraph>
              <CommonParagraph>
                <ContactSlideoutLink text="Connect with our Selby team" /> today to learn more about
                accessory dwelling units and how they could help you achieve your real estate goals!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
